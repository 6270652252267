import request from '@/global/xhr'
// import qs from 'qs'

export function requestAdd(data){
  return request({
    url: '/api/officialWebsite/requestAdd',
    method: 'post',
    data: data
  })
}

export function requestAddCompany(data){
  return request({
    url: '/api/officialWebsite/requestAddCompany',
    method: 'post',
    data: data
  })
}