<template>
  <div class="box">
    <div class="header clearfix">
      <div class="pull-left">
        <img
          v-lazy="require('../../assets/images/home_img_Logo_nor.png')"
          alt=""
          class="logo"
        />
      </div>
      <div class="pull-left">
        <span class="st">LEVC TX５指定出行服务运营商</span>
      </div>
      <div class="pull-right tab-img">
        <img
          v-if="showTab"
          v-lazy="require('../../assets/images/home_ic_table_nor.png')"
          alt=""
          @click="showTab = false"
        />
        <img
          class="backNor"
          v-if="!showTab"
          v-lazy="require('../../assets/images/all_ic_back_nor.png')"
          alt=""
          @click="showTab = true"
        />
      </div>
    </div>
    <div class="container">
      <div class="first" id="infomation" v-show="!showServer">
        <img src="../../assets/images/home_img_1_nor.png" alt="" />
        <!-- <div class="abTitle">一站式尊享出行平台</div> -->
      </div>
      <div class="second" v-show="!showServer">
        <div class="title">关于礼帽出行</div>
        <div class="text-box">
          <div class="text">
            <div>礼帽出行，专注预约订制出行服务。</div>
            由吉利控股集团旗下LEVC全新打造。
          </div>
        </div>
        <!-- <div class="geely">
          <img src="../../assets/images/home_img_geely_nor.png" alt="" />
        </div> -->
      </div>
      <div class="thrid" id="infomation2" v-show="!showServer">
        <div class="headText">满足每一个预约订制需求</div>
        <div class="sheadText">你留恋旅途的风景 我更在乎你的前行</div>
        <div class="swiper-box">
          <swiper
            :autoplay="swiperOptions.autoplay"
            :loop="swiperOptions.loop"
            :slides-per-view="2"
            :space-between="30"
            :speed="swiperOptions.speed"
            class="swiper-container"
          >
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/h5_3-1.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">全家六人行</div>
              <div class="swiper-sinfo">快乐出行全家桶</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/h5_3-2.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">女士安心行</div>
              <div class="swiper-sinfo">高强度安全隔离</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/h5_3-3.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">移动会客厅</div>
              <div class="swiper-sinfo">商务款待更体面</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/h5_3-4.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">大件轻松行</div>
              <div class="swiper-sinfo">1.5m超大空间座舱</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/h5_3-5.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">无障碍出行</div>
              <div class="swiper-sinfo">全程管家式贴心服务</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/h5_3-6.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">英式婚车订制</div>
              <div class="swiper-sinfo">专享英式浪漫婚礼</div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="four" id="infomation3" v-show="!showServer">
        <!-- <div class="headText">车辆高度订制化&nbsp;&nbsp;满足多种出行场景</div> -->
        <img
          v-lazy="require('../../assets/images/home_img_car_nor.png')"
          alt=""
        />
      </div>
      <div class="five" id="infomation4" v-show="!showServer">
        <div class="headText">人文设计关怀　只为更懂你</div>
        <div class="sheadText">
          一个多世纪的专注 让每一次出行都成为流动的风景
        </div>
        <div class="swiper-box">
          <swiper
            :autoplay="swiperOptions.autoplay"
            :loop="swiperOptions.loop"
            :slides-per-view="2"
            :space-between="30"
            :speed="swiperOptions.speed"
            class="swiper-container"
          >
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/h5_5-1.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">更安全的出行</div>
              <div class="swiper-sinfo">全铝车身　极致工艺</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/h5_5-2.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">更舒适的乘坐体验</div>
              <div class="swiper-sinfo">对六座　大空间</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/h5_5-3.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">更便利的出行</div>
              <div class="swiper-sinfo">斜坡踏板　自如出发</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/h5_5-4.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">更私密的洽谈</div>
              <div class="swiper-sinfo">高强度隔断　语音系统</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/h5_5-5.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">更持久的旅程</div>
              <div class="swiper-sinfo">增程动力系统　里程无忧</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/h5_5-6.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">更有爱的关怀</div>
              <div class="swiper-sinfo">调节面板凸点盲文设计</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/h5_5-7.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">更贴心的旋转座椅</div>
              <div class="swiper-sinfo">腿脚不便也可体面入座</div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="six" id="infomation5" v-show="showServer">
        <img v-lazy="require('../../assets/images/t88.png')" alt="" />
        <div class="bai_box1">
          <img v-lazy="require('../../assets/images/t36.png')" alt="" />
          <div class="title1">
            <span>LEVC起源于1908年</span><br />
            <span>专注打造英式高端出行座驾的百年汽车品牌</span>
          </div>
          <div class="title2">
            <span>礼帽出行，专注预约订制出行服务</span><br />
            <span>由吉利控股集团旗下LEVC全新打造</span>
          </div>
        </div>
        <!-- 第一个 -->
        <div class="bai_box2">
          <img v-lazy="require('../../assets/images/t72.jpg')" alt="" />
        </div>
        <div class="bai_box3">
          源于伦敦的世界高端出行品牌开创者<br />
          From London，it is the first high-end professional brand for travel in
          the world.
        </div>
        <!-- two -->
        <div class="bai_box2 cbox2">
          <img v-lazy="require('../../assets/images/t71.jpg')" alt="" />
        </div>
        <div class="bai_box3">
          英国皇室成员和名流人士的出行首选<br />
          The Black Cab is the first choice for British royals and celebrities
          when they need to travel the city of London daily.
        </div>
        <!-- three -->
        <div class="bai_box2 cbox3">
          <img v-lazy="require('../../assets/images/t72.jpg')" alt="" />
        </div>
        <div class="bai_box3">
          经典车型Black Cab FX&TX系列成为英伦文化标志之一<br />
          The iconic Black Cab FX&TX series has already become a symbol of
          British culture.
        </div>

        <div class="bai_box4">
          <img
            class="img"
            v-lazy="require('../../assets/images/t37.png')"
            alt=""
          />
          <img v-lazy="require('../../assets/images/6-3.png')" alt="" />
        </div>
        <div class="bai_box5">
          <div class="title">凯博德公司 1908-1973年</div>
          <div class="line1"></div>
          <div class="line"></div>
          <div class="contnt1">
            1908-1973年间，英系豪车车身制造专家凯博德为LEVC注入了豪车基因。凯博德成功打造了FX3和FX4等经典英式商务车型，并于1958年率先将自动挡实现标配，实现技术引领。同时，凯博德致力于高端豪华车型车身的设计与开发，先后为劳斯莱斯、宾利、戴姆勒(英国)、捷豹等多个英国知名汽车品牌设计和生产车身，积累了深厚的豪车设计生产经验。
          </div>

          <div class="title mrg20px">英国锰铜控股公司 1973-2013年</div>
          <div class="line1"></div>
          <div class="line"></div>
          <div class="contnt1">
            专注安全商务出行座驾的锰铜控股 (Manganese Bronze
            Holdings)为LEVC注入了安全基因。锰铜控股全资收购凯博德，延续打造了
            一代经典车型FX系列并开创了TX系列，成为时代经典，被人们以伦敦为名昵称为“London
            Cab”。通过不断锤炼提升车辆品质与安全性，打造高端商务出行座驾国际最高标准。
          </div>
          <img v-lazy="require('../../assets/images/t62.jpg')" alt="" />
        </div>

        <div class="bai_box6">
          <div class="title">现在</div>
          <div class="line1"></div>
          <div class="line"></div>
          <div class="contnt1">
            <div>2013年，LEVC开始致力于可持续创新的高端出行。</div>
            <div>
              2018年，全新TX5在伦敦上市，并迅速进入巴黎、柏林、阿姆斯特丹、东京等国际都市。
            </div>
            <div>
              2021年，LEVC将正式入驻中国，为LEVC TX5构建了一个专属的线上出行平台—礼帽出行，专注预约订制出行服务；将更多可持续创新出行理念传递给消费者，为他们打造全新的出行体验。
            </div>
          </div>
        </div>
        <img
          class="bai_box7"
          v-lazy="require('../../assets/images/h5_6-5.png')"
          alt=""
        />
      </div>

      <div class="seven" id="infomation6" v-show="!showServer">
        <div class="headText">有礼有面 礼帽司机招募中</div>
        <div class="sheadText">共创有温度、高品质出行</div>
        <ul class="clearfix">
          <li>
            <img v-lazy="require('../../assets/images/7-1@2x.png')" alt="" />
            <div class="text">品牌可信赖</div>
          </li>
          <li>
            <img v-lazy="require('../../assets/images/7-3@2x.png')" alt="" />
            <div class="text">收入更可观</div>
          </li>
          <li>
            <img v-lazy="require('../../assets/images/7-2@2x.png')" alt="" />
            <div class="text">权益有保障</div>
          </li>
          <li>
            <img v-lazy="require('../../assets/images/7-4@2x.png')" alt="" />
            <div class="text">发展空间大</div>
          </li>
        </ul>
      </div>

      <div class="nine" v-show="!showServer">
        <div class="headText">期待您的加入</div>
        <div>
          <input type="text" v-model="name" placeholder="请输入您的称呼" />
        </div>
        <div>
          <input type="number" v-model="phone" placeholder="请输入您的手机号" />
        </div>
        <div class="input-arg">
          <van-checkbox v-model="checked" />
          我已阅读并同意<router-link :to="{path:'/arg-reg'}" target="_blank">《司机招募隐私政策》</router-link>
        </div>
        <div><button type="submit" @click="submitJoin" :disabled="!checked">提交</button></div>
      </div>

      <!-- <div class="ten" v-show="showServer">
        <div id="infomation7">
          <div class="headText">预约客户经理面谈</div>
          <div>
            <input
              type="text"
              v-model="managerName"
              placeholder="您的称呼"
              maxlength="30"
            />
          </div>
          <div>
            <input
              type="number"
              v-model="managerPhone"
              placeholder="手机号码"
              maxlength="11"
            />
          </div>
          <div><button type="submit" @click="submitManager">提交</button></div>
        </div>
        <div class="centennial-classic">
          <div class="bigtitle">百年经典 英伦风范</div>
          <div class="smalltitle">最新车型国内首发运营</div>
          <div class="centennial-flex-box">
            <div class="flex-child">
              <div class="img-box">
                <img src="../../assets/images/TX-5@2x.png" alt="" />
              </div>
              <div class="classic-info">LEVC TX-5</div>
              <div class="classic-info">增程式/纯电版 支持换电</div>
            </div>
            <div class="flex-child">
              <div class="img-box">
                <img src="../../assets/images/BX@2x.png" alt="" />
              </div>
              <div class="classic-info">LEVC BX</div>
              <div class="classic-info">增程式/纯电版 支持换电</div>
            </div>
          </div>
        </div>
        <div class="british-fan">
          <div class="bigtitle">无需大量资金 即刻拥有英伦范儿</div>
          <div class="smalltitle">现提供以下企业服务</div>
          <div class="british-box">
            <div class="british-img">
              <img src="../../assets/images/home_img_shishi_nor.png" alt="" />
            </div>
            <div class="british-big">企业出行</div>
            <div class="british-small">
              员工便捷用车，安全舒适，企业账户统一支付，高效管理
            </div>
          </div>
          <div class="british-box">
            <div class="british-img">
              <img
                src="../../assets/images/home_img_changduanzu_nor.png"
                alt=""
              />
            </div>
            <div class="british-big">长短租</div>
            <div class="british-small">
              30天到3年任你选择，随到随租，送车上门，服务无忧
            </div>
          </div>
          <div class="british-box">
            <div class="british-img">
              <img src="../../assets/images/home_img_baoche_nor.png" alt="" />
            </div>
            <div class="british-big">包车</div>
            <div class="british-small">
              支持1-30日包车，配备专业认证司机，安全贴心
            </div>
          </div>
        </div>
      </div> -->
      <div class="footer">
        <div class="linkbox clearfix">
          <div>
            <div class="pull-left">
              <div>
                <span>商务联系</span>
              </div>
              <div>
                <span>友情链接</span>
              </div>
              <div style="margin-top: 48px">
                <span>平台相关</span>
              </div>
            </div>
            <div class="pull-left width50">
              <div>
                <a href="javascript:;">bd@limaocx.com</a>
              </div>
              <div>
                <div style="width: 200px">
                  <a href="https://www.levcauto.com/">LEVC官网(中国)</a>
                  <div><a href="https://www.levc.com/">LEVC官网(国际)</a></div>
                </div>
                <div><a href="http://zgh.com/">吉利控股集团</a></div>
                <div>
                  <a href="javascript:;" @click="toAggren">保险条款</a>&nbsp;
                  <a href="javascript:;" @click="toPrice">价格公示</a>&nbsp;
                  <a href="javascript:;" @click="toAbout">关于我们</a>&nbsp;
                  <a href="javascript:;" @click="toConnect">联系我们</a>
                </div>
              </div>
            </div>
          </div>
          <div class="pull-right">
            <!-- <div class="mrg_r">
              <img src="../../assets/images/h5_xiazai.png" alt="" />
              <div class="gongzhong">礼帽出行APP</div>
            </div> -->
            <div>
              <img src="../../assets/images/home_img_weixin_nor.png" alt="" />
              <div class="gongzhong">微信公众号</div>
            </div>
          </div>
        </div>
        <div class="footerTip">
          <div>
            <a href="https://beian.miit.gov.cn">浙ICP备2021011876号</a>&nbsp;
          </div>
          <div>
            <a href="javascript:;">浙江礼帽出行科技有限公司 copyright@2021</a
            >&nbsp;<br />
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802012380" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
            <img
                style="vertical-align: middle"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAC3FBMVEUAAAD+/ODz6Kr//+PeqFfYrn3x167k0JXoxnyaaVzhs2ifaFXbrGLkvFnpyF7v2X/kwm3cp1nhsGfqw3rZqG3ntVzjrFPt3oDjvGnfr2fbnFGti3q0lH7ktoLryXn9v1T4znr/74bnvGz034v+2I/ktoDz6ZLkwY/Dfz7buoftzYbq2IPr0pjs3bLv6KPRrnbKhFv79ND488n/+dDZr4Lx38f/+cH/95f42oL7/97s2Y3++uzw1rvTk3DmuloAAHkBAm7uzWYAAGXktV3qvFr/0ljksE7fo0rWHxhrdocAAIAABHf143Pyy27w1GwGA2jtymHpwWDqxV/qyVyTeFrrwFflwFPislP+xVLpsErbmUfVkEbysETemUTpgj7ThT3XdTg5FDjdhTXWZTDaTCm7TCbTOCLXPiD9LA/QFg3UAwnOAQOEj5kcPpdyhZSptJEACJFpfo4AG44XMInFvYfTvIejmYSVkINyeoJzdoK9un6SjX7FrnwAEHp8enny2HjWwHjKtnhcX3jYzHeNhnfu2HWUjHWsonPNwnH70m9WTm8AAW//723pym3dtmn/0mbnxGa0o2ZeWWb8zGT/4mPtwmJuYmL/22D/vmB5ZGC9kF7/2l0MAF3uyFqnjVn4xFjYnli0mVi5i1jiqVfyyVbmtlbXkVNUOFPlvFLpt1LNrFKjfVLuvlBgHlDsuU/ouU9ONU/ov05ODk7/2E02Gk3jqkqEaUr/tUngjkf7n0bXikb6xERCJETdn0LckUG1gD/ooD3Ulj3jkz3TZT3WjjzOeDqBWDr3pDnglTlMADnbbTf2gjbkbzaTYDZpAjbplzTtcTTEazPXXzOeXzDscS3MPi38jizJWSrVSCrrXynzfCjVdCjZRyjTQCbFUiTlYCPXPSHLPSHWMR/wXh7iRh7GPh3PLBrSIRrWGhfMJxPGJxPRDBG/ABG2ABCxDg7BDAvEGArZAAbJAALPAADa4ry/AAAAPnRSTlMACEIaxqxpAvv7+ff19PDs7Ovn5uXk5OHg29LRy8fEw8G+vLqysaufnJiVk4yDfG9dXFpMSEFBNTApJyEcFO3QiBQAAAFzSURBVBjTYoACZjYZaTZmBmRgxsp9+di21ZysxggxxlmJZy/ev9LXnriIEa5VYUPIray0lOyd+ctVoKKWXFsmXXvu8exO5vsZnnuErcCC5m1e8x5nPXrxOu3TzSqHFguQmI18tff+Jx89HqR7fE5v7q5TtAYK6h8v81p4Ovv6wbAdmRc6HMpddYGCmudrCqbtTn2anHBq15SZ9iUx6kBBkSTfXIfUuBsPL909c9i/uP6EJFAQMJ6j2/Ps32Yk30uIy3jjXxgRLwEUVN07ubTo5LsPr16mXD1X29gZrgUUlN23uD/H28lp09o5TvYVs523ygEFORYsO+TbEOI5cVVTV+XUA1Fu/EBBoxXu0bfnT98cEePa45oUHR7MBHK9IV9Y/BFHFzc7R7/YqF4BsBiDqVBw0NLQoMAAF3c7vwmCEEFln1ZnZxe3wJWx7nZ2jj5qkNDU5l2/ZE3kusjQuRsDxPXYoQFqa6DBIiUmyqKkYwIWAgD35oZAL/mkFwAAAABJRU5ErkJggg=="
                alt=""
              />&nbsp;浙公网安备 33010802012380号</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!showTab" class="tabContain">
      <ul>
        <li @click="changeIndex(0)"><a href="javascript:;">首页</a></li>
        <li @click="changeIndex(1)"><a href="javascript:;">预约出行</a></li>
        <li @click="changeIndex(2)"><a href="javascript:;">英伦座驾</a></li>
        <li @click="changeIndex(3)"><a href="javascript:;">品质服务</a></li>
        <li @click="changeIndex(4)"><a href="javascript:;">百年经典</a></li>
        <!-- <li @click="changeIndex(6)"><a href="javascript:;">政企服务</a></li> -->
        <li @click="changeIndex(5)"><a href="javascript:;">司机招募</a></li>
        <!-- <li @click="goH5()"><a href="javascript:;">企业版</a></li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { reactive, onMounted, toRefs } from "vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
SwiperCore.use([Autoplay, Pagination, Navigation]);
import { isMobile } from "@/utils/validate";
import { Toast } from "vant";
import { requestAdd, requestAddCompany } from "@/api/officialWebsite";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    toAggren() {
      this.$router.push("/h5_aggrement");
    },
    toPrice() {
      this.$router.push("/h5_price");
    },
    toAbout() {
      this.$router.push("/h5_about");
    },
    toConnect() {
      this.$router.push("/h5_connect");
    },
    // goH5(){
    //   this.$router.push("/h5-enterprise")
    // }
  },
  setup() {
    const store = useStore();
    const state = reactive({
      checked: false,
      showServer: false,
      showTab: true,
      name: "",
      phone: "",
      managerName: "",
      managerPhone: "",
      swiperOptions: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        loop: true,
        speed: 1000,
      },
    });
    const checkPhone = () => {
      if (!isMobile(state.phone) && state.phone.length > 0) {
        Toast("请输入您正确的手机号");
        return;
      }
    };

    const checkManagerPhone = () => {
      if (!isMobile(state.managerPhone) && state.managerPhone.length > 0) {
        Toast("请输入您正确的手机号");
        return;
      }
    };

    const submitJoin = () => {
      if (!state.phone || !state.name) {
        Toast("请输入您的称呼、手机号");
        return;
      }
      if (!isMobile(state.phone) && state.phone.length > 0) {
        Toast("请输入您正确的手机号");
        return;
      }
      requestAddFun();
    };
    const submitManager = () => {
      if (!state.managerPhone || !state.managerName) {
        Toast("请填写您的称呼/请填写手机号码");
        return;
      }
      if (!isMobile(state.managerPhone) && state.managerPhone.length > 0) {
        Toast("请填写正确的手机号码");
        return;
      }
      requestAddCompanyFun();
    };

    const requestAddCompanyFun = () => {
      requestAddCompany({
        customerName: state.managerName,
        mobile: state.managerPhone,
        source: 20,
      }).then((res) => {
        if (res.returnCode === 10000) {
          Toast(res.msg);
          state.managerName = "";
          state.managerPhone = "";
        } else {
          Toast(res.msg);
        }
      });
    };

    const changeIndex = (data) => {
      state.showTab = true;
      state.showServer = false;
      if (data == 0) {
        setTimeout(function () {
          document.querySelector("#infomation").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 0);
      } else if (data == 1) {
        setTimeout(function () {
          document.querySelector("#infomation2") &&
            document.querySelector("#infomation2").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
        }, 0);
      } else if (data == 2) {
        setTimeout(function () {
          document.querySelector("#infomation3").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 0);
      } else if (data == 3) {
        setTimeout(function () {
          document.querySelector("#infomation4").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 0);
      } else if (data == 4) {
        setTimeout(function () {
          state.showServer = true;
          document.querySelector("#infomation5").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          scroll(0, 0);
        }, 0);
      } else if (data == 5) {
        setTimeout(function () {
          document.querySelector("#infomation6").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 0);
      } else if (data == 6) {
        state.showServer = true;
        setTimeout(function () {
          document.querySelector("#infomation7").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 0);
      }
    };
    const requestAddFun = () => {
      requestAdd({ customerName: state.name, mobile: state.phone, source: 20 })
        .then((res) => {
          console.log(res);
          if (res.returnCode === 10000) {
            Toast(res.msg);
            state.name = "";
            state.phone = "";
          } else {
            Toast(res.msg);
          }
        })
        .catch((err) => {
          Toast(err);
        });
    };

    onMounted(() => {
      store.commit("loading/HIDELOADING");
    });
    return {
      ...toRefs(state),
      checkPhone,
      changeIndex,
      submitJoin,
      submitManager,
      checkManagerPhone,
    };
  },
};
</script>
<style>  
.van-checkbox {
  margin-right: 8px;
}
.van-checkbox__icon--checked .van-icon {
  background-color: #000;
  border-color: #000;
}
button:disabled {
  background: #999 !important;
  border-color: #999 !important;
}
</style>
<style scoped lang="scss">
.bai_box1 {
  height: 230px;
  background: #333;

  padding-top: 36px;
  padding-bottom: 27px;
  text-align: center;

  .title1 {
    font-size: 12px;
    padding-top: 6px;
    color: #a7a7a7;
    line-height: 180%;
  }
  .title2 {
    font-size: 12px;

    padding-top: 12px;
    color: #a7a7a7;
    line-height: 180%;
  }
}
.bai_box2 {
  width: 100%;
  height: 70vh;
  background: url(../../assets/images/t67.jpg) no-repeat 0px -1px;
  background-size: 100% 100%;
  position: relative;
  margin-top: -1px;
  img {
    position: absolute;
    left: 20px;
    bottom: -60px;
  }
}
.cbox2 {
  background: url(../../assets/images/t69.jpg) no-repeat;
  background-size: 100% 100%;
}
.cbox3 {
  background: url(../../assets/images/t68.jpg) no-repeat;
  background-size: 100% 100%;
}

.bai_box3 {
  font-size: 3.5vw;
  line-height: 6vw;
  margin: 3vw auto 0;
  width: 88%;
  padding-bottom: 5vw;
  text-align: left;
}
.bai_box4 {
  height: 240px;
  background-color: #323232;
  text-align: center;
  color: #fff;
  overflow: hidden;
  padding-bottom: 5vh;
  .img {
    margin-top: 5vh;
  }
}
.bai_box5 {
  padding: 30px 20px;
  background: #e9e9e9;
  text-align: left;
  color: #000;

  .title {
    font-family: PingFang SC;
    font-style: normal;
    font-size: 16px;
    font-weight: bold;
  }
  .line1 {
    width: 23px;
    height: 0px;
    display: inline-block;
    margin-bottom: 10px;
    border-top: 3px solid #333333;
  }
  .line {
    width: 23px;
    margin-top: 5px;
    margin-bottom: 10px;
    display: inline-block;
    border-top: 3px solid #ffd700;
    transform: rotate(180deg);
  }
  .contnt1 {
    font-family: PingFang SC, Lantinghei SC, Helvetica Neue, Helvetica, Arial,
      Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, STHeitiSC-Light, simsun,
      \\5b8b\4f53, WenQuanYi Zen Hei, WenQuanYi Micro Hei, "sans-serif";
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #666;
  }
  img {
    margin-top: 20px;
  }
}
.bai_box6 {
  padding: 30px 20px;
  text-align: left;
  color: #000;
  .title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }
  .line1 {
    width: 23px;
    height: 0px;
    display: inline-block;
    margin-bottom: 10px;
    border-top: 3px solid #333333;
  }
  .line {
    width: 23px;
    margin-top: 5px;
    margin-bottom: 10px;
    display: inline-block;
    border-top: 3px solid #ffd700;
  }
  .contnt1 {
    font-family: PingFang SC, Lantinghei SC, Helvetica Neue, Helvetica, Arial,
      Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, STHeitiSC-Light, simsun,
      \\5b8b\4f53, WenQuanYi Zen Hei, WenQuanYi Micro Hei, "sans-serif";
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #666;
    div {
      margin-top: 10px;
    }
  }
}
.mrg20px {
  margin-top: 20px;
}
.bai_box7 {
  margin-bottom: -5px;
}
html {
  height: 100%;
}
body {
  font-size: 62.5%;
  height: 100%;
  font-family: PingFang SC, Lantinghei SC, Helvetica Neue, Helvetica, Arial,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, STHeitiSC-Light, simsun, \\5b8b\4f53,
    WenQuanYi Zen Hei, WenQuanYi Micro Hei, "sans-serif";
}
.box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.header {
  height: 2.875rem;
  padding: 0.75rem 1rem;
  font-size: 0.75rem;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.header .logo {
  display: inline-block;
  width: 88px;
  height: 1.375rem;
}

.header .tab-img img {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
}

.backNor {
  animation: dropdown 0.5s 1;
}
@keyframes dropdown {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(90deg);
  }
}

.tabContain {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 2.8rem;
  overflow-y: auto;
  background-color: #fff;
  padding-top: 3.125rem;
  z-index: 999;
}

@keyframes showBar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.tabContain ul {
  margin-left: 1rem;
  animation: showBar 1s 1;
}
.tabContain ul li {
  padding: 1.375rem 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  font-size: 0.875rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  text-align: left;
}
.container {
  flex: 1;
  overflow-y: auto;
}
.header .st {
  color: #222222;
  font-size: 0.75rem;
  line-height: 1.5625rem;
  padding-left: 1.0625rem;
}
.first {
  position: relative;
  background-color: #fff;
}
.first img {
  width: 100%;
}
.first .abTitle {
  position: absolute;
  width: 100%;
  left: 0;
  top: 3.75rem;
  font-size: 1.875rem;
  font-family: PingFang SC, Lantinghei SC, Helvetica Neue, Helvetica, Arial,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, STHeitiSC-Light, simsun, \\5b8b\4f53,
    WenQuanYi Zen Hei, WenQuanYi Micro Hei, "sans-serif";
  font-weight: 200;
  color: #ffffff;
}
.second {
  padding: 0 1rem;
  padding-bottom: 50px;
  background: #ffffff;
}
.second .text-box {
  border: 0.9px solid #17191e;
  padding: 1rem;
}
.second .text {
  font-size: 0.75rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 100;
  color: #222222;
  line-height: 1.125rem;
  // text-indent: 1.5rem;
  text-align: left;
}
// .second .text:first-child {
//   // padding-bottom: 0.75rem;
// }
.second .title {
  font-size: 1.125rem;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 200;
  color: #222222;
  line-height: 1.375rem;
  padding: 1.875rem 0 1.25rem 0;
}
.second .geely {
  text-align: center;
  padding: 1.25rem 0 3.125rem 0;
}
.second .geely img {
  display: block;
  width: 7.5rem;
  height: 6.875rem;
}
.thrid {
  background: #f8f8f8;
  padding: 1.875rem 1rem 3.125rem 1rem;
}
.swiper-box {
  text-align: left;
  margin-top: 1.25rem;
}
.swiper-box .swiper-info {
  font-size: 0.875rem;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #222222;
  line-height: 1rem;
  margin-bottom: 0.25rem;
  margin-top: 0.5rem;
}

.swiper-box .swiper-sinfo {
  font-size: 0.625rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 100;
  color: #222222;
  line-height: 0.875rem;
}
.swiper-box .banner-swiper-item {
  overflow: hidden;
  margin-right: 1rem;
}
.swiper-box .banner-swiper-item img {
  display: block;
  width: 100%;
  height: 100%;
}

// .thrid .headText {
//   font-size: 1.125rem;
//   font-family: PingFang-SC-Regular, PingFang-SC;
//   font-weight: 200;
//   color: #222222;
//   line-height: 1.375rem;
//   text-align: center;
// }
// .thrid .headText:first-child {
//   padding-bottom: 0.375rem;
// }

.four {
  // background: url("../../assets/images/home_img_car_nor.png") no-repeat top
  // center;
  background-size: 100% 100%;
  // padding-top: 1.875rem;
  // padding-bottom: 3.125rem;
  // min-height: 21.9375rem;
}
.four img {
  display: block;
  width: 100%;
}
.four .headText {
  font-size: 1.125rem;
  font-family: PingFang SC, Lantinghei SC, Helvetica Neue, Helvetica, Arial,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, STHeitiSC-Light, simsun, \\5b8b\4f53,
    WenQuanYi Zen Hei, WenQuanYi Micro Hei, "sans-serif";
  font-weight: 300;
  color: #222222;
  line-height: 1.375rem;
  padding-bottom: 5.3125rem;
}
.five {
  background: #ffffff;
  padding: 1.75rem 1rem 2.125rem 1rem;
}
.headText {
  text-align: center;
  font-size: 1.125rem;
  font-family: PingFang SC, Lantinghei SC, Helvetica Neue, Helvetica, Arial,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, STHeitiSC-Light, simsun, \\5b8b\4f53,
    WenQuanYi Zen Hei, WenQuanYi Micro Hei, "sans-serif";
  font-weight: 300;
  color: #222222;
  line-height: 1.375rem;
  padding-bottom: 0.375rem;
}

.sheadText {
  font-size: 0.75rem;
  font-family: PingFang SC, Lantinghei SC, Helvetica Neue, Helvetica, Arial,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, STHeitiSC-Light, simsun, \\5b8b\4f53,
    WenQuanYi Zen Hei, WenQuanYi Micro Hei, "sans-serif";
  font-weight: 300;
  color: #222222;
  line-height: 0.875rem;
  padding-bottom: 20px;
}
.five .img-box img {
  display: block;
  width: 100%;
}
.five .img-box .img-text {
  font-size: 0.875rem;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 100;
  color: #222222;
  line-height: 1rem;
  text-align: left;
  padding: 0.625rem 0 1rem 0;
}
.six {
  background-color: #fff;
}
.six img {
  display: block;
  width: 100%;
}
.seven {
  padding: 1.875rem 1rem 0.625rem 1rem;
  background: #fff;
}
.headText {
  text-align: center;
  font-size: 1.125rem;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #222222;
  line-height: 1.375rem;
  padding-bottom: 0.375rem;
}

.sheadText {
  font-size: 0.75rem;
  font-family: PingFang SC, Lantinghei SC, Helvetica Neue, Helvetica, Arial,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, STHeitiSC-Light, simsun, \\5b8b\4f53,
    WenQuanYi Zen Hei, WenQuanYi Micro Hei, "sans-serif";
  font-weight: 300;
  color: #222222;
  line-height: 0.875rem;
  padding-bottom: 20px;
}
.seven ul {
  margin-left: -0.5625rem;
  margin-right: -0.5625rem;
  width: calc(100% + 1.125rem);
}
.seven ul li {
  float: left;
  margin-right: 0.5625rem;
  margin-left: 0.5625rem;
  width: calc(50% - 1.125rem);
}
.seven ul li img {
  display: block;
  width: 100%;
}
.seven ul li .text {
  font-size: 0.75rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 0.875rem;
  padding: 0.625rem 0 1.25rem 0;
}
.nine,
.ten {
  padding: 2rem 1rem 1.75rem;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.04) 0%,
    rgba(0, 0, 0, 0) 2%
  );
}
.gongzhong {
  font-size: 12px;
  transform: scale(0.8);
  // width: 73px;
}
.nine .headText,
.ten .headText {
  font-size: 1.125rem;
  font-family: PingFang SC, Lantinghei SC, Helvetica Neue, Helvetica, Arial,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, STHeitiSC-Light, simsun, \\5b8b\4f53,
    WenQuanYi Zen Hei, WenQuanYi Micro Hei, "sans-serif";
  font-weight: 300;
  color: #222222;
  line-height: 1.375rem;
  padding-bottom: 1.875rem;
}
.nine input,
.ten input {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  width: 100%;
  height: 3rem;
  padding: 1rem;
  border: 0;
  margin-bottom: 1.25rem;
  font-size: 14px;
  text-align: center;
}

button {
  width: 10rem;
  height: 2.5rem;
  background: #181818;
  border-radius: 1.25rem;
  color: #fff;
  border: 0;
}
.footer {
  background: rgba(0, 0, 0, 0.05);
  padding-bottom: 1rem;
}
.flex {
  display: fl;
}
.linkbox {
  padding: 1rem;
  width: 100%;
  display: flex;
}
.linkbox img {
  width: 50%;
}
.linkbox2 {
  width: 50%;
  padding: 0 0rem;
  font-size: 0.625rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.75rem;
  display: flex;
  justify-content: left;
  margin-left: -4px;
}
.linkbox2 a:first-child {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.linkbox2 .pull-left {
  text-align: left;
  // margin-right: px;
  transform: scale(0.7);
}
.linkbox2 .pull-right {
  text-align: left;
  width: calc(100% - 13rem);
  transform: scale(0.7);
}
.linkbox .pull-right {
  width: 4.0625rem;
  display: flex;
}
.mrg_r {
  margin-right: 12px;
}
.linkbox .pull-left {
  text-align: left;
}
.w {
  display: block;
  width: 244px;
}
.linkbox .pull-left span {
  font-size: 0.625rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 0.75rem;
  display: inline-block;
  padding-right: 1rem;
  line-height: 1.5rem;
  height: 1.5rem;
}

.linkbox .pull-left a {
  font-size: 10px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #222222;
  line-height: 1.5rem;
  height: 1.5rem;
}
.linkbox .pull-right img {
  display: block;
  width: 4.0625rem;
  height: 4.0625rem;
}
.width50 {
  width: 62%;
}
.ten {
  padding: 0;
}
#infomation7 {
  padding: 100px 68px 0;
  min-height: 580px;
  background: url("../../assets/images/zhenqi_img_1_nor@2x.png") no-repeat;
  background-size: cover;
}
#infomation7 .headText {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 22px;
}

#infomation7 input {
  background: rgba(0, 0, 0, 0.54);
  border-radius: 8px;
  color: #fff;
}
#infomation7 button {
  width: 100%;
  background: #f9d230;
  border-radius: 8px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #17191e;
}
.centennial-classic {
  padding: 0 16px 30px;
}
.centennial-classic .bigtitle {
  font-size: 18px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #222222;
  line-height: 22px;
  padding-top: 30px;
  text-align: center;
}
.centennial-classic .smalltitle {
  font-size: 12px;
  font-family: PingFang-SC-Light, PingFang-SC;
  font-weight: 300;
  color: #222222;
  padding-top: 6px;
  text-align: center;
}

.centennial-flex-box {
  padding-top: 20px;
  margin-left: -4px;
  margin-right: -4px;
  width: calc(100% + 8px);
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.centennial-flex-box .flex-child {
  width: calc(50% - 8px);
}
.centennial-flex-box .flex-child .img-box {
  padding-bottom: 9px;
}
.centennial-flex-box .flex-child .img-box img {
  display: block;
  width: 100%;
}
.centennial-flex-box .flex-child .classic-info {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 16px;
  text-align: left;
}
.british-fan {
  padding: 30px 16px 34px;
  background: #f8f8f8;
}
.british-fan .bigtitle {
  font-size: 18px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #222222;
  line-height: 22px;
}

.british-fan .smalltitle {
  font-size: 12px;
  font-family: PingFang-SC-Light, PingFang-SC;
  font-weight: 300;
  color: #222222;
  line-height: 14px;
  padding-top: 6px;
  padding-bottom: 20px;
}
.british-box .british-img img {
  display: block;
  width: 100%;
}
.british-box .british-big {
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #222222;
  line-height: 16px;
  text-align: left;
  padding-top: 10px;
}

.british-box .british-small {
  font-size: 10px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #222222;
  line-height: 14px;
  text-align: left;
  padding-top: 4px;
  padding-bottom: 16px;
}
.footerTip a {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.input-arg {
  display: flex;
  font-size: 14px;
  margin-bottom: 16px;
  a {
    color: #409eff;
  }
}
</style>
