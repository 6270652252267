<template>
  <el-container>
    <el-header class="header">
      <div class="header-left">
        <div class="p10px">
          <img
            src="../../assets/images/limaoLogo.png"
            alt=""
            style="width: 111px; height: 30px"
          />
        </div>
        <span>LEVC TX５指定出行服务运营商</span>
      </div>
      <!-- 首页导航 -->
      <ul class="clearfix">
        <li>
          <a href="javascript:;" @click="changeIndex(0)"
            >首页 <span class="line" v-if="activeIndex == 0"></span
          ></a>
        </li>
        <li>
          <a href="javascript:;" @click="changeIndex(1)"
            >预约出行 <span class="line" v-if="activeIndex == 1"></span
          ></a>
        </li>
        <li>
          <a href="javascript:;" @click="changeIndex(2)"
            >英伦座驾 <span class="line" v-if="activeIndex == 2"></span
          ></a>
        </li>
        <li>
          <a href="javascript:;" @click="changeIndex(3)"
            >品质服务 <span class="line" v-if="activeIndex == 3"></span
          ></a>
        </li>
        <li>
          <a href="javascript:;" @click="changeIndex(6)"
            >百年经典 <span class="line" v-if="activeIndex == 6"></span
          ></a>
        </li>
        <!-- <li>
          <a href="javascript:;" @click="changeIndex(6)"
            >政企服务 <span class="line" v-if="activeIndex == 6"></span
          ></a>
        </li> -->
        <li>
          <a href="javascript:;" @click="changeIndex(4)"
            >司机招募 <span class="line" v-if="activeIndex == 4"></span
          ></a>
        </li>
        <!-- <li>
          <a href="/lmcx-enterprise" target="_blank">
            企业版 
          </a>
        </li> -->
      </ul>
    </el-header>

    <el-main>
      <!-- 首页大图加介绍 -->
      <div class="first-box" id="infomation" v-if="!showServer">
        <div class="kong"></div>
        <div class="oneP">
          <img
            src="../../assets/images/1.jpg"
            alt=""
            width="720px"
            height="700px"
          />
          <!-- <div class="oneP1">伦敦百年出行专车TX５特需出行服务运营商</div>
          <div class="oneP2">懂礼 更懂你</div> -->
        </div>
      </div>
      <div class="second" v-show="!showServer">
        <div class="second-box clearfix">
          <div class="pull-left img-box">
            <img v-lazy="require('../../assets/images/2.jpg')" alt="" />
          </div>
          <div class="pull-left logo-box">
            <div class="info-box1">
              <div>礼帽出行，专注预约订制出行服务。</div>
              <span>由吉利控股集团旗下LEVC全新打造。</span>
            </div>
            <!-- <div class="info-box2">
              秉承“以礼为本”的理念，为全家多人出行、女性夜间出行、无障碍出行、大件出行、商务出行、
              英式婚车订制等提供专属预约订制出行服务，打造有温度的品质出行，懂礼更懂你。
            </div> -->
            <!-- <div class="img-box2">
              <img v-lazy="../../assets/images/logo.png" alt="" />
            </div> -->
          </div>
        </div>
      </div>
      <!-- 出行管家 -->
      <div
        class="third-box"
        ref="cxgjRef"
        id="infomation2"
        v-show="!showServer"
      >
        <div class="info">满足每一个预约订制需求</div>
        <div class="sinfo">你留恋旅途的风景 我更在乎你的前行</div>
        <div class="swiper-box">
          <swiper
            :autoplay="swiperOptions.autoplay"
            :loop="swiperOptions.loop"
            :slides-per-view="3"
            :space-between="30"
            :speed="swiperOptions.speed"
            class="swiper-container"
          >
            <swiper-slide class="banner-swiper-item">
              <div>
                <img v-lazy="require('../../assets/images/3-1.png')" alt="" />
              </div>
              <div class="swiper-info">全家六人行</div>
              <div class="swiper-sinfo">快乐出行全家桶</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img v-lazy="require('../../assets/images/3-2.png')" alt="" />
              </div>
              <div class="swiper-info">女士安心行</div>
              <div class="swiper-sinfo">高强度安全隔离</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img v-lazy="require('../../assets/images/3-3.png')" alt="" />
              </div>
              <div class="swiper-info">移动会客厅</div>
              <div class="swiper-sinfo">商务款待更体面</div>
            </swiper-slide>

            <swiper-slide class="banner-swiper-item">
              <div>
                <img v-lazy="require('../../assets/images/3-4.png')" alt="" />
              </div>
              <div class="swiper-info">大件轻松行</div>
              <div class="swiper-sinfo">1.5m超大空间座舱</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img v-lazy="require('../../assets/images/3-5.png')" alt="" />
              </div>
              <div class="swiper-info">无障碍出行</div>
              <div class="swiper-sinfo">全程管家式贴心服务</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img v-lazy="require('../../assets/images/3-6.png')" alt="" />
              </div>
              <div class="swiper-info">英式婚车订制</div>
              <div class="swiper-sinfo">专享英式浪漫婚礼</div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <!-- 英伦座驾 -->
      <div
        class="forth-box"
        ref="ylzjRef"
        id="infomation3"
        v-show="!showServer"
      >
        <!-- <div class="name">车辆高度订制化 满足多种出行场景</div> -->
        <div class="rel-box">
          <img v-lazy="require('../../assets/images/yinglun.png')" alt="" />
          <div>生而出行 百年专车LEVC TX5</div>
          <!-- <span class="sp1">私密语音系统</span>
          <span class="sp2">旋转折叠座椅</span>
          <span class="sp3">超大后门90度开启</span>
          <span class="sp4">斜坡踏板</span> -->
        </div>
        <!-- <img v-lazy="../assets/images/四屏.png" alt="" /> -->
      </div>
      <!-- 品质服务 -->
      <div class="five-box" ref="pzfwRef" id="infomation4" v-show="!showServer">
        <div class="info">人文设计关怀　只为更懂你</div>
        <div class="sinfo">一个多世纪的专注 让每一次出行都成为流动的风景</div>
        <div class="img-box">
          <swiper
            :autoplay="swiperOptions.autoplay"
            :loop="swiperOptions.loop"
            :slides-per-view="3"
            :space-between="30"
            :speed="swiperOptions.speed"
            class="swiper-container"
          >
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/5-1@2x.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">更安全的出行</div>
              <div class="swiper-sinfo">全铝车身　极致工艺</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/5-2@2x.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">更舒适的乘坐体验</div>
              <div class="swiper-sinfo">对六座　大空间</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/5-3@2x.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">更便利的出行</div>
              <div class="swiper-sinfo">斜坡踏板　自如出发</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/5-4@2x.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">更私密的洽谈</div>
              <div class="swiper-sinfo">高强度隔断　语音系统</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/5-5@2x.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">更持久的旅程</div>
              <div class="swiper-sinfo">增程动力系统　里程无忧</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/5-6@2x.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">更有爱的关怀</div>
              <div class="swiper-sinfo">调节面板凸点盲文设计</div>
            </swiper-slide>
            <swiper-slide class="banner-swiper-item">
              <div>
                <img
                  v-lazy="require('../../assets/images/5-7@2x.png')"
                  alt=""
                />
              </div>
              <div class="swiper-info">更贴心的旋转座椅</div>
              <div class="swiper-sinfo">腿脚不便也可体面入座</div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <!-- 百年经典 -->
      <div class="six-box" ref="bnjdRef" id="infomation5" v-show="showServer">
        <img v-lazy="require('../../assets/images/6-1.png')" alt="" />
        <div class="bai_box1">
          <div class="title">百年经典</div>
          <div class="line"></div>
          <div class="title1">
            <span>LEVC起源于1908年</span><br />
            <span>专注打造英式高端出行座驾的百年汽车品牌</span>
          </div>
          <div class="title2">
            <span>礼帽出行，专注预约订制出行服务</span><br />
            <span>由吉利控股集团旗下LEVC全新打造</span>
          </div>
        </div>
        <!-- 第一个 -->
        <div class="bai_box2">
          <img v-lazy="require('../../assets/images/6-7.png')" alt="" />
          <div class="title">
            <img v-lazy="require('../../assets/images/t55.png')" alt="" />
          </div>
        </div>

        <div class="bai_box3">
          <div class="content">
            <div>源于伦敦的世界高端出行品牌开创者</div>
            <div>
              From London，it is the first high-end professional brand for
              travel in the world.
            </div>
          </div>
        </div>
        <!-- 第二个 -->
        <div class="bai_box2 mrg95px">
          <div class="title">
            <img v-lazy="require('../../assets/images/t56.png')" alt="" />
          </div>
          <img v-lazy="require('../../assets/images/6-6.png')" alt="" />
        </div>

        <div class="bai_box3">
          <div class="content">
            <div>英国皇室成员和名流人士的出行首选</div>
            <div>
              The Black Cab is the first choice for British royals and
              celebrities when they need to travel the city of London daily.
            </div>
          </div>
        </div>
        <!-- 第三个 -->
        <div class="bai_box2 mrg95px">
          <img v-lazy="require('../../assets/images/6-2.png')" alt="" />
          <div class="title">
            <img v-lazy="require('../../assets/images/t57.png')" alt="" />
          </div>
        </div>

        <div class="bai_box3">
          <div class="content">
            <div>经典车型Black Cab FX&TX系列成为英伦文化标志之一</div>
            <div>
              The iconic Black Cab FX&TX series has already become a symbol of
              British culture.
            </div>
          </div>
        </div>
        <div class="bai_box4 mrg95px">
          <div class="ti">品牌历史</div>
          <div class="line"></div>
          <img v-lazy="require('../../assets/images/6-3.png')" alt="" />
        </div>
        <div class="bai_box5">
          <div class="title">凯博德公司 1908-1973年</div>
          <div class="line1"></div>
          <div class="line"></div>
          <div class="contnt1">
            1908-1973年间，英系豪车车身制造专家凯博德为LEVC注入了豪车基因。凯博德成功打造了FX3和FX4等经典英式商务车型，并于1958年率先将自动挡实现标配，实现技术引领。同时，凯博德致力于高端豪华车型车身的设计与开发，先后为劳斯莱斯、宾利、戴姆勒(英国)、捷豹等多个英国知名汽车品牌设计和生产车身，积累了深厚的豪车设计生产经验。
          </div>

          <div class="title">英国锰铜控股公司 1973-2013年</div>
          <div class="line1"></div>
          <div class="line"></div>
          <div class="contnt1">
            专注安全商务出行座驾的锰铜控股 (Manganese Bronze
            Holdings)为LEVC注入了安全基因。锰铜控股全资收购凯博德，延续打造了
            一代经典车型FX系列并开创了TX系列，成为时代经典，被人们以伦敦为名昵称为“London
            Cab”。通过不断锤炼提升车辆品质与安全性，打造高端商务出行座驾国际最高标准。
          </div>
          <img v-lazy="require('../../assets/images/6-4.png')" alt="" />
        </div>
        <div class="bai_box6">
          <div class="title">现在</div>
          <div class="line1"></div>
          <div class="line"></div>
          <div class="contnt1">
            <div>2013年，LEVC开始致力于可持续创新的高端出行。</div>
            <div>
              2018年，全新TX5在伦敦上市，并迅速进入巴黎、柏林、阿姆斯特丹、东京等国际都市。
            </div>
            <div>
              2021年，LEVC将正式入驻中国，为LEVC TX5构建了一个专属的线上出行平台—礼帽出行，专注预约订制出行服务；将更多可持续创新出行理念传递给消费者，为他们打造全新的出行体验。
            </div>
          </div>
        </div>
        <img v-lazy="require('../../assets/images/6-5.png')" alt="" />
      </div>
      <div
        class="seven-box"
        ref="jrwmRef"
        id="infomation6"
        v-show="!showServer"
      >
        <div class="info">有礼有面 礼帽司机招募中</div>
        <div class="sinfo">共创有温度、高品质出行</div>
        <div class="seven-img-box">
          <div class="img-box">
            <div class="simg-box">
              <img v-lazy="require('../../assets/images/7-1.png')" alt="" />
            </div>
            <div class="title">品牌可信赖</div>
            <div class="stitle">LEVC x 吉利集团 大品牌值得信赖</div>
          </div>
          <div class="img-box">
            <div class="simg-box">
              <img v-lazy="require('../../assets/images/7-3.png')" alt="" />
            </div>
            <div class="title">收入更可观</div>
            <div class="stitle">基础薪资高+高激励奖励 收入上限更高</div>
          </div>
          <div class="img-box">
            <div class="simg-box">
              <img v-lazy="require('../../assets/images/7-2.png')" alt="" />
            </div>
            <div class="title">权益有保障</div>
            <div class="stitle">社保保障更安心</div>
          </div>
          <div class="img-box">
            <div class="simg-box">
              <img v-lazy="require('../../assets/images/7-4.png')" alt="" />
            </div>
            <div class="title">发展空间大</div>
            <div class="stitle">平台快速发展，提供多样化职业发展路径</div>
          </div>
        </div>
      </div>
      <!-- 加入我们 -->
      <div class="eight-box clearfix" v-show="!showServer">
        <div class="pull-left left-box">
          <img v-lazy="require('../../assets/images/8.png')" alt="" />
        </div>
        <div class="pull-left right-box">
          <div class="title">期待您的加入</div>
          <div class="input-box">
            <el-input v-model="name" placeholder="请输入您的称呼"></el-input>
          </div>
          <div class="input-box">
            <el-input
              v-model="phone"
              type="tel"
              placeholder="请输入您的手机号"
            ></el-input>
          </div>
          <div class="input-arg">
            <div>
              <el-checkbox v-model="checked" />
              我已阅读并同意<router-link :to="{path:'/arg-reg'}" target="_blank">《司机招募隐私政策》</router-link>
            </div>
          </div>
          <div class="button-box">
            <el-button type="primary" @click="submitJoin" :disabled="!checked">提交</el-button>
          </div>
        </div>
      </div>
      <!-- 政企服务 -->
      <!-- <div v-show="showServer">
        <div class="ten-box" id="infomation7">
          <div class="server-box">
            <div class="title">预约客户经理面谈</div>
            <div class="input-box">
              <el-input
                v-model="managerName"
                placeholder="您的称呼"
                maxlength="30"
              ></el-input>
            </div>
            <div class="input-box">
              <el-input
                maxlength="11"
                v-model="managerPhone"
                type="tel"
                placeholder="手机号码"
              ></el-input>
            </div>
            <div class="button-box">
              <el-button type="primary" @click="submitManager">提交</el-button>
            </div>
          </div>
        </div>
        <div class="server-btitle">百年经典 英伦风范</div>
        <div class="server-stitle">最新车型国内首发运营</div>
        <div class="server-flex-box">
          <div class="flex-child">
            <div class="img-box">
              <img src="../../assets/images/TX-5@2x.png" alt="" />
            </div>
            <div class="flex-child-title">LEVC TX-5 增程式/纯电版 支持换电</div>
          </div>
          <div class="flex-child">
            <div class="img-box">
              <img src="../../assets/images/BX@2x.png" alt="" />
            </div>
            <div class="flex-child-title">LEVC BX 增程式/纯电版 支持换电</div>
          </div>
        </div>
        <div class="yl-box">
          <div class="server-btitle">无需大量资金 即刻拥有英伦范儿</div>
          <div class="server-stitle">现提供以下企业服务</div>
          <div class="server-flex-yl">
            <div class="flex-child">
              <div class="img-box">
                <img src="../../assets/images/3-1.png" alt="" />
              </div>
              <div class="yl-child-title">企业出行</div>
              <div class="yl-child-title2">
                员工便捷用车，安全舒适，企业账户统一支付，高效管理
              </div>
            </div>
            <div class="flex-child">
              <div class="img-box">
                <img src="../../assets/images/3-2.png" alt="" />
              </div>
              <div class="yl-child-title">长短租</div>
              <div class="yl-child-title2">
                30天到3年任你选择，随到随租，送车上门，服务无忧
              </div>
            </div>
            <div class="flex-child">
              <div class="img-box">
                <img src="../../assets/images/3-3.png" alt="" />
              </div>
              <div class="yl-child-title">包车</div>
              <div class="yl-child-title2">
                支持1-30日包车，配备专业认证司机，安全贴心
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 尾部 -->
      <Footer />
    </el-main>
  </el-container>
</template>
<script>
import { useStore } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { reactive, onMounted, toRefs, ref } from "vue";
import { useRouter } from "vue-router";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
SwiperCore.use([Autoplay, Pagination, Navigation]);
import { isMobile } from "@/utils/validate";
import { ElMessage } from "element-plus";
import { requestAdd, requestAddCompany } from "@/api/officialWebsite";
import Footer from "./footer.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    Footer,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const cxgjRef = ref(null);
    const ylzjRef = ref(null);
    const pzfwRef = ref(null);
    const bnjdRef = ref(null);
    const jrwmRef = ref(null);
    const state = reactive({
      checked: false,
      showServer: false,
      activeIndex: 0,
      name: "",
      phone: "",
      managerName: "",
      managerPhone: "",
      swiperOptions: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        loop: true,
        speed: 1000,
      },
      swiperOptions2: {
        loop: false,
      },
      bannerHeight: 673,
    });
    const handleScroll = () => {
      if (!state.showServer) {
        setTimeout(() => {
          let scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop;

          if (scrollTop < cxgjRef.value._value.offsetTop - 60) {
            state.activeIndex = 0;
          } else if (
            scrollTop >= cxgjRef.value._value.offsetTop - 60 &&
            scrollTop < ylzjRef.value.offsetTop - 60
          ) {
            state.activeIndex = 1;
          } else if (
            scrollTop >= ylzjRef.value.offsetTop - 60 &&
            scrollTop < pzfwRef.value.offsetTop - 60
          ) {
            state.activeIndex = 2;
          } else if (
            scrollTop >= pzfwRef.value.offsetTop - 60 &&
            scrollTop < jrwmRef.value.offsetTop - 60
          ) {
            state.activeIndex = 3;
          }
          // } else if (
          //   scrollTop >= bnjdRef.value.offsetTop - 60 &&
          //   scrollTop < jrwmRef.value.offsetTop - 60
          // ) {
          //   state.activeIndex = 4;
          // }
          else if (scrollTop >= jrwmRef.value.offsetTop - 60) {
            state.activeIndex = 4;
          }
        }, 500);
      } else {
        state.activeIndex = 6;
      }
    };

    const changeIndex = (data = 0) => {
      if (typeof data == Object) return;
      state.showServer = false;
      state.activeIndex = data;
      if (data < 6) {
        if (data == 0) {
          setTimeout(function () {
            document.querySelector("#infomation").scrollIntoView({
              block: "start",
            });
          }, 0);
        } else if (data == 1) {
          setTimeout(function () {
            document.querySelector("#infomation2").scrollIntoView({
              block: "start",
            });
          }, 0);
        } else if (data == 2) {
          setTimeout(function () {
            document.querySelector("#infomation3").scrollIntoView({
              block: "start",
            });
          }, 0);
          console.log(2);
        } else if (data == 3) {
          setTimeout(function () {
            document.querySelector("#infomation4").scrollIntoView({
              block: "start",
            });
          }, 0);
        } else if (data == 4) {
          setTimeout(function () {
            document.querySelector("#infomation6").scrollIntoView({
              block: "start",
            });
          }, 0);
        }
      }
      if (data == 6) {
        document.body.scrollTop = 0;
        window.pageYOffset = 0;
        document.documentElement.scrollTop = 0;
        state.showServer = true;
      }


    };

    const checkPhone = () => {
      if (!isMobile(state.phone) && state.phone.length > 0) {
        ElMessage.error("请输入您正确的手机号");
        return;
      }
    };

    const checkManagerPhone = () => {
      if (!isMobile(state.managerPhone) && state.managerPhone.length > 0) {
        ElMessage.error("请输入您正确的手机号");
        return;
      }
    };

    const submitManager = () => {
      if (!state.managerPhone || !state.managerName) {
        ElMessage.error("请填写您的称呼/请填写手机号码");
        return;
      }
      if (!isMobile(state.managerPhone) && state.managerPhone.length > 0) {
        ElMessage.error("请填写正确的手机号码");
        return;
      }
      requestAddCompanyFun();
    };

    const submitJoin = () => {
      if (!state.phone || !state.name) {
        ElMessage.error("请输入您的称呼、手机号");
        return;
      }
      if (!isMobile(state.phone) && state.phone.length > 0) {
        ElMessage.error("请输入您正确的手机号");
        return;
      }
      requestAddFun();
    };

    const requestAddFun = () => {
      requestAdd({
        customerName: state.name,
        mobile: state.phone,
        source: 10,
      }).then((res) => {
        if (res.returnCode === 10000) {
          ElMessage.success(res.msg);
          state.name = "";
          state.phone = "";
        } else {
          ElMessage.error(res.msg);
        }
      });
    };

    const requestAddCompanyFun = () => {
      requestAddCompany({
        customerName: state.managerName,
        mobile: state.managerPhone,
        source: 10,
      }).then((res) => {
        if (res.returnCode === 10000) {
          ElMessage.success(res.msg);
          state.managerName = "";
          state.managerPhone = "";
        } else {
          ElMessage.error(res.msg);
        }
      });
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
      store.commit("loading/HIDELOADING");
      setTimeout(() => {
        changeIndex(store.getters.homeIndex);
      }, 200);
    });
    return {
      ...toRefs(state),
      cxgjRef,
      ylzjRef,
      pzfwRef,
      bnjdRef,
      jrwmRef,
      handleScroll,
      changeIndex,
      checkPhone,
      submitJoin,
      submitManager,
      checkManagerPhone,
    };
  },
};
</script>
<style>
.input-arg .el-checkbox__inner {
  width: 16px;
  height: 16px;
  font-size: 16px;
}
.input-arg .el-checkbox__input.is-checked .el-checkbox__inner{
  background: #000;
  border-color: #000;
} 
.input-arg .el-checkbox__inner:hover,
.input-arg .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #000;
}
.el-button--primary.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover{
  background-color: #999 !important;
  border-color: #999 !important;
}
</style>
<style scoped lang="scss">
img[lazy="loading"] {
  display: block;
  width: 50px !important;
  height: 50px !important;
  margin: 0 auto;
}
.p10px {
  padding-right: 10px;
  display: inline-block;
}
.el-container {
  background: #fff;
  // height: 100vh;
  color: #222;
  .el-header {
    padding: 15px 0px;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    margin: 0px auto;
    background: #ffffff;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    min-width: 1200px;
    height: 60px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    z-index: 999;
    .header-left {
      width: 28%;
      img {
        vertical-align: middle;
        display: inline-block;
        margin-right: px;
      }
      span {
        vertical-align: middle;
        color: #222222;
        border-left: 1px solid #e6e6e6;
        display: inline-block;
        padding-left: 10px;
        font-family: PingFangSC-Light;
        border-radius: 1px;
      }
    }
    ul {
      width: 65%;
      li {
        float: left;
        a {
          margin-left: 60px;
          display: inline-block;
          line-height: 30px;
          font-family: PingFangSC-Regular;
          position: relative;
          height: 32.5px;
        }
        a:after {
          content: " ";
          position: absolute;
          z-index: 2;
          bottom: 0px;
          left: 50%;
          display: block;
          width: 18px;
          height: 1px;
          transform: translate(-50%);
        }
        a:hover:after {
          height: 2px;
          animation: ad_width 0.1s linear forwards;
          background: #181818;
        }
        .line {
          box-sizing: border-box;
          line-height: 10px;
          display: block;
          width: 18px;
          height: 2.5px;
          border: 1px solid #181818;
          border-radius: 20%;
          background: #181818;
          margin: 0 auto;
        }
        @keyframes ad_width {
          from {
            width: 0;
          }

          to {
            width: 18px;
          }
        }
      }
    }
  }
  // .oneP {
  //   position: relative;
  //   .oneP1 {
  //     position: absolute;
  //     font-size: 38px;
  //     font-family: PingFangSC-Thin, PingFang SC;
  //     font-weight: 100;
  //     color: #ffffff;
  //     line-height: 48px;
  //     letter-spacing: 3px;
  //     top: 60px;
  //   }
  //   .oneP2 {
  //     position: absolute;
  //     top: 133px;
  //     font-size: 45px;
  //     font-family: PingFangSC-Regular, PingFang SC;
  //     font-weight: 400;
  //     color: #ffffff;
  //     line-height: 48px;
  //     letter-spacing: 4px;
  //   }
  // }
  .first-box,
  .six-box {
    width: 100%;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .first-box {
    .kong {
      height: 60px;
    }
  }
  .el-main {
    // height: calc(100vh - 60px);
    overflow-x: hidden;
    padding: 0;
    // margin-top: 60px;
    width: 100%;
    #infomation7 {
      height: 890px;
      margin-top: 60px;
      background: url("../../assets/images/server-home.png") no-repeat;
      background-size: cover;
      position: relative;
    }
    #infomation7 .server-box {
      position: absolute;
      right: 160px;
      top: 253px;
      width: 420px;
      height: 384px;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(20px);
      text-align: center;
      overflow: hidden;
      padding: 48px 60px 30px;
      box-sizing: border-box;
    }

    #infomation7 .server-box .title {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      padding-bottom: 0;
    }
    ::v-deep .server-box {
      .el-input {
        margin-top: 20px;
        .el-input__inner {
          color: #fff;
          border-radius: 0;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          background-color: inherit;
          text-align: center !important;
        }
      }
    }
    #infomation7 .button-box {
      .el-button {
        margin-top: 50px;
        width: 100%;
        background: #f9d230;
        border-radius: 6px;
        border-color: #f9d230;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #17191e;
      }
    }
    .server-btitle {
      padding-top: 100px;
      text-align: center;
      font-size: 40px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #222222;
      line-height: 44px;
    }

    .server-stitle {
      padding-top: 24px;
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #222222;
      line-height: 24px;
    }
    .server-flex-box {
      padding: 71px 179px 124px;
      margin-left: -40px;
      margin-right: -40px;
      width: calc(100% + 80px);
      display: flex;
      justify-content: space-between;
      .flex-child {
        width: calc(50% - 40px);
        .img-box {
          // height: 452px;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .flex-child-title {
          font-size: 24px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #222222;
          line-height: 28px;
          text-align: left;
          padding-top: 23px;
        }
      }
    }
    .yl-box {
      background-color: #f8f8f8;
    }
    .server-flex-yl {
      padding: 71px 179px 124px;
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
      transform: translate3d(0, 0, 0);
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      .flex-child {
        width: calc(33% - 30px);
        .img-box {
          // height: 430px;
          img {
            width: 100%;
            // height: 430px;
            display: block;
          }
        }
        .yl-child-title {
          padding-top: 23px;
          text-align: left;
          font-size: 24px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #222222;
          line-height: 28px;
        }
        .yl-child-title2 {
          padding-top: 16px;
          text-align: left;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #222222;
          line-height: 20px;
        }
      }
    }

    .forth-box {
      font-family: PingFangSC-Light;
      // height: 890px;
      // background: url("../../assets/images/bg.png") no-repeat;
      // background-size: cover;
      .name {
        font-size: 40px;
        color: #222222;
        padding: 100px 0 120px 0;
      }
      .rel-box {
        position: relative;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        div {
          position: absolute;
          top: 100px;
          left: 50%;
          font-size: 40px;
          margin-left: -242px;
          color: #fff;
          font-family: PingFangSC-Light;
          font-weight: 200;
        }
        img {
          display: block;
          width: 100%;
        }
        // span {
        //   font-family: PingFangSC-Regular;
        //   color: #222;
        // }
        // .sp1 {
        //   position: absolute;
        //   top: 0;
        //   left: 40px;
        // }
        // .sp2 {
        //   position: absolute;
        //   top: -30px;
        //   left: 265px;
        // }
        // .sp3 {
        //   position: absolute;
        //   bottom: 152px;
        //   left: -135px;
        // }
        // .sp4 {
        //   position: absolute;
        //   bottom: -25px;
        //   left: 187px;
        // }
      }
    }
    .second {
      background: #f8f8f8;
      padding: 95px 60px;
      min-width: 1200px;
    }

    .second-box {
      margin: 0 auto;
      height: auto;
      display: flex;
      .img-box {
        width: 46%;
        height: auto;
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .logo-box {
        padding-left: 60px;
        width: 54%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .info-box1 {
          text-align: left;

          font-family: PingFangSC-Light;
          color: #222222;
          font-size: 20px;
          line-height: 50px;

          div {
            line-height: 1.5;
          }
          // text-overflow: -o-ellipsis-lastline;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // line-clamp: 2;
          // -webkit-box-orient: vertical;
        }
        @media only screen and (min-width: 1281px) {
          .info-box1 {
            font-size: 32px;
          }
        }
        .info-box2 {
          font-size: 18px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #222222;
          line-height: 30px;
          text-align: left;

          margin-top: 110px;
          margin-bottom: 40px;
          // text-overflow: -o-ellipsis-lastline;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // line-clamp: 2;
          // -webkit-box-orient: vertical;
        }
        @media only screen and (min-width: 1281px) {
          .info-box2 {
            font-size: 18px;
          }
        }
        .img-box2 {
          text-align: center;
          img {
            display: block;
            width: 240px;
            height: 220px;
            //   height: 200px;
          }
        }
        @media only screen and (min-width: 1440px) {
          img {
            margin-top: 0px;
          }
        }
        @media only screen and (min-width: 1680px) {
          img {
            margin-top: 70px;
          }
        }
      }
    }
    .info {
      font-family: PingFangSC-Light;
      text-align: center;
      color: #222222;
      font-size: 44px;
      padding-top: 100px;
    }
    .sinfo {
      font-family: PingFangSC-Light;
      text-align: center;
      color: #222222;
      font-size: 20px;
      margin-top: 24px;
      margin-bottom: 72px;
    }
    .third-box {
      background: #ffffff;

      .title {
        font-family: PingFangSC-Light;
        padding: 100px 0 120px 0;
        text-align: center;
        color: #222222;
        font-size: 40px;
      }
    }
    .five-box {
      background: #f8f8f8;
      padding-bottom: 120px;
      .info {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 44px;
        padding-top: 100px;
      }
      .sinfo {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 72px;
      }
      .img-box {
        display: flex;
        padding: 0 190px;
        justify-content: space-between;
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
        .five-img-box {
          width: calc(33% - 30px);
          margin: 15px;
          .simg-box {
            width: 100%;
            img {
              display: block;
              width: 100%;
            }
          }
          .title {
            text-align: left;
            font-size: 24px;
            margin-top: 24px;
          }
          .stitle {
            text-align: left;
            font-size: 13px;
            margin-top: 16px;
          }
        }
      }
    }
    .swiper-box {
      display: flex;
      padding: 0 190px 120px 190px;
      justify-content: space-between;
    }
    .swiper-container {
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
      overflow: hidden;
      .banner-swiper-item {
        width: calc(33% - 30px);
        height: 100%;
        overflow: hidden;
        display: block;
        position: relative;
        img {
          display: block;
          width: 100%;
          border: none;
        }
        .swiper-info {
          text-align: left;
          font-size: 24px;
          color: #222222;
          margin-top: 24px;
        }
        .swiper-sinfo {
          text-align: left;
          font-size: 16px;
          color: #222222;
          margin-top: 16px;
          line-height: 16px;
        }
      }
    }
    .seven-box {
      // padding-top: 100px;
      background: #fff;
      padding-bottom: 139px;
      .info {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 40px;
        // margin-top: 100px;
      }
      .sinfo {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 72px;
      }
      .seven-img-box {
        display: flex;
        padding: 0 60px;
        justify-content: space-between;
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
        min-width: 1200px;
        .img-box {
          width: calc(25% - 30px);
          margin: 15px;
          background: #f1f1f1;
          .simg-box {
            width: 100%;
            img {
              display: block;
              width: 100%;
            }
          }
          .title {
            text-align: left;
            font-size: 18px;
            margin-top: 10px;
            padding-left: 13px;
            // padding-bottom: 13px;
            font-family: PingFangSC-Regular;
          }
          .stitle {
            padding: 0 13px 13px 13px;
            text-align: left;
            font-size: 14px;
            margin-top: 8px;
            line-height: 16px;
            font-family: PingFangSC-Light;
          }
        }
      }
    }
    .nine-box {
      padding-top: 100px;
    }
    .manager-box {
      width: 52%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // padding-left: 117px;
      .title {
        font-family: PingFangSC-Light;
        padding-bottom: 100px;
        font-size: 40px;
        color: #222222;
      }
      .input-box {
        // margin-left: 93px;
        // width: 360px;
        margin-bottom: 30px;
        .el-input {
          width: 360px;
          .el-input__inner {
            height: 50px;
            width: 360px;
            font-family: PingFang-SC-Medium;
          }
        }
      }
      .button-box {
        padding-bottom: 136px;
        .el-button {
          background: #181818;
          width: 160px;
          border-color: #181818;
        }
      }
    }
    .eight-box {
      display: flex;
      min-width: 1200px;
      height: auto;
      .left-box {
        width: 48%;
        display: flex;
        justify-content: right;
        height: auto;
        img {
          display: block;
          height: auto;
          width: 100%;
        }
      }
      .right-box {
        width: 52%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding-left: 117px;
        background: #f8f8f8;
        // padding-top: 60px;
        .title {
          font-family: PingFangSC-Light;
          padding-bottom: 50px;
          font-size: 40px;
          color: #222222;
        }
        .input-box {
          // margin-left: 93px;
          // width: 360px;
          margin-bottom: 30px;
          .el-input {
            width: 360px;
            .el-input__inner {
              height: 50px;
              width: 360px;
              font-family: PingFang-SC-Medium;
            }
          }
        }
        .button-box {
          // padding-bottom: 136px;
          .el-button {
            background: #181818;
            width: 160px;
            border-color: #181818;
          }
        }
      }
    }
  }
}
.bai_box1 {
  height: 349px;
  background: #333;
  padding-top: 24px;
  text-align: center;
  margin-bottom: 58px;

  .line {
    width: 228px;
    height: 0px;
    display: inline-block;
    border-top: 2px solid #ffd700;
  }
  .title {
    height: 70px;
    color: #d6d6d6;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title1 {
    padding-top: 32px;
    color: #a7a7a7;
    line-height: 180%;
  }
  .title2 {
    padding-top: 32px;
    color: #a7a7a7;
    line-height: 180%;
  }
}
.bai_box2 {
  margin: 0px 58px 0px;
  display: flex;
  justify-content: space-between;

  background-color: #000;

  > img {
    width: 90% !important;
    height: auto !important;
  }
  .title {
    color: #ffffff;
    display: flex;
    flex-direction: column-reverse;
    // margin-bottom: 85px;
    // padding-bottom: 60px;
    align-items: center;
    img {
      width: 90% !important;
      max-height: 100% !important;
      transform: scale(0.8, 0.8);
    }
  }
}
.bai_box3 {
  height: 116px;
  margin-top: 55px;
  margin-bottom: 28px;
  .content {
    padding-left: 100px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 160%;
    text-align: left;
  }
}
.bai_box4 {
  // height: 1050px;
  background-color: #323232;
  text-align: center;
  color: #fff;
  overflow: hidden;
  background: url("../../assets/images/img3.png") no-repeat #323232;
  // background-size: cover;
  padding-bottom: 20vh;
  .ti {
    margin-top: 5vh;
    text-align: center;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 5vh;
    color: #d6d6d6;
  }

  .line {
    width: 226px;
    height: 0px;
    display: inline-block;
    border-top: 2px solid #ffd700;
    margin-bottom: 5vh;
  }
}
.mrg95px {
  margin-top: 95px;
}
.bai_box5 {
  height: 1050px;
  padding-top: 100px;
  padding-left: 60px;
  background: #e9e9e9;
  text-align: left;

  .title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
  }
  .line1 {
    width: 33.7px;
    height: 0px;
    display: inline-block;
    margin-bottom: 39px;
    border-top: 3px solid #333333;
  }
  .line {
    width: 112.56px;
    margin-top: 27px;
    margin-bottom: 39px;
    display: inline-block;
    border-top: 3px solid #ffd700;
    transform: rotate(180deg);
  }
  .contnt1 {
    width: 1560px;
    height: 117px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    margin-bottom: 48px;
    color: #666;
  }
  img {
    margin-top: -12px;
  }
}
.bai_box6 {
  height: 419px;
  padding-top: 95px;
  padding-left: 60px;
  text-align: left;
  .title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
  }
  .line1 {
    width: 33.7px;
    height: 0px;
    display: inline-block;
    margin-bottom: 39px;
    border-top: 3px solid #333333;
  }
  .line {
    width: 112.56px;

    margin-top: 27px;
    margin-bottom: 39px;
    display: inline-block;
    border-top: 3px solid #ffd700;
    transform: rotate(180deg);
  }
  .contnt1 {
    height: 198px;

    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 160%;
    color: #838383;
  }
}
.el-message {
  min-width: 100px;
}
.input-arg {
  margin: -10px 0 26px;
  font-size: 14px;
  text-align: center;
  >div{
    width: 360px;
    text-align: left;
    display: inline-block;
    a {
      color: #409eff;
    }
  }
}
</style>