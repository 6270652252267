<template>
  <router-view>
    <component :is="currentpage"></component>
  </router-view>
</template>

<script>
// import { ElLoading } from "element-plus";
import { reactive, onMounted, toRefs } from "vue";
import pchome from "./pc/home";
import h5home from "./h5/home";
export default {
  components: {
    pchome,
    h5home,
  },
  setup() {
    const state = reactive({
      currentpage: "pchome",
    });
    const clientWidth = () => {
      if (/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
        state.currentpage = "h5home";
      } else {
        state.currentpage = "pchome";
      }
      // let width = document.documentElement.clientWidth;
      // state.currentpage = width < 1000 ? "h5home" : "pchome";
    };

    onMounted(() => {
      clientWidth();
      window.addEventListener("resize", clientWidth);
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style>
</style>
